






























































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  nextTick,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [
        {
          id: "",
          amount: 0,
          text: "",
          discountCodeValue: "",
        },
      ],
    },
    reserveList: {
      type: Array,
      required: false,
      defaul: () => [
        {
          id: "",
          amount: 0,
          text: "",
          discountCodeValue: "",
        },
      ],
    },
    currency: {
      type: String,
      required: false,
      default: "PLN",
    },
  },

  setup(props, { root, emit }) {
    const { checkIfIsOnReserve } = useMisc({ root });
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      empty: false,
      table: false,
      loaded: false,
      items: [] as any[],
      selectItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      selectedServices: [] as any[],
      servicesDiscounts: [] as any,
      servicesDiscountsError: "",
      reserveList: [] as string[],
    });

    const model = ref<{
      [key: string]: {
        [key: string]: { checked: boolean; amount: number; text: string };
      };
    }>({});

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(
          `event/${root.$route.params.id}/additional-service-group/participant`,
          {
            params: { sortBy: ["name"], sortDesc: [false] },
          }
        )
        .then(({ data: { additionalServiceGroups } }) => {
          state.items = additionalServiceGroups;
          model.value = Object.fromEntries(
            state.items.map((item) => [
              item.id,
              Object.fromEntries(
                item.additionalServices.map((service: any) => [
                  service.id,
                  {
                    checked: false,
                    amount: 0,
                    text: "",
                    discountCodeValue: "",
                  },
                ])
              ),
            ])
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const isChecked = (group: string, service: string) =>
      !!model.value[group][service]?.checked;

    const isAnyChecked = (group: string) =>
      Object.values(model.value[group]).some((service) => service.checked);

    const unsetChecked = (group: string) => {
      Object.keys(model.value[group]).forEach((service) => {
        model.value[group][service].checked = false;
      });
    };

    const switchChecked = (group: string, service: string) => {
      if (
        state.items.find((item) => item.id === group)?.formType === "multiple"
      ) {
        model.value[group][service].checked = !model.value[group][service]
          ?.checked;
      } else {
        unsetChecked(group);
        nextTick(() => (model.value[group][service].checked = true));
      }
    };

    const onModelUpdate = (model: any) => {
      const value: any[] = [];

      Object.values(model).forEach((group: any) =>
        Object.entries(group).forEach(([id, service]: any[]) => {
          if (service.checked && service.amount > 0) {
            state.selectedServices.push({
              id: id,
              selectedAmount: service.amount,
              text: service.text || undefined,
              discountCodeValue: service.discountCodeValue || null,
            });
            value.push({
              id: id,
              selectedAmount: service.amount,
              text: service.text || undefined,
              discountCodeValue: service.discountCodeValue || null,
            });
          }
        })
      );

      emit("input", value);
    };

    watch(model, onModelUpdate, { deep: true });

    const assignToReserveList = () => {
      state.reserveList.forEach((el: string) => {
        props.reserveList?.push({
          id: el,
          selectedAmount: 0,
          text: undefined,
          discountCodeValue: null,
        });
      });
    };
    watch(() => state.reserveList, assignToReserveList, { deep: true });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("panel.event.discount.discountCodeNotFound")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const checkDiscountCode = (code: string, id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`discount-code/${code}/additionalService/${id}`)
        .then(({ data }) => {
          state.servicesDiscounts.unshift({
            id: id,
            discount: data,
          });
          state.servicesDiscountsError = "";

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.discount.codeValid"),
          });
        })
        .catch((error) => {
          state.error = error.response?.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    return {
      state,
      model,
      isChecked,
      switchChecked,
      unsetChecked,
      isAnyChecked,
      checkDiscountCode,
      checkIfIsOnReserve,
    };
  },
});
